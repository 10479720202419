<template>
  <div id="app">
    <!-- <Home></Home> -->
    <router-view></router-view>
  </div>
</template>

<script>
import Home from './views/Home.vue'
export default {
    name: "App",
    components:{
      Home
    }
  }
</script>

<style lang="scss"></style>
