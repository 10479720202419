import Vue from 'vue'
import VueRouter from 'vue-router'
import Router from 'vue-router'

const Home=()=>import('../views/Home')
const Solution=()=>import('../views/Solution')
const Detail=()=>import('../views/Detail')
const Consultition=()=>import('../views/Consultition')
const SolutionInfo=()=>import('../views/Solution_Detail.vue')




Vue.use(Router)

const routes=[
  {
    path:'',
    redirect:'/home'
  },
  {
    path:'/home',
    component:Home
  },
  {
    path:'/solution',
    component:Solution
  },
  {
    path:'/solution-info',
    component:SolutionInfo
  },
  {
    path:'/detail/:id',
    component:Detail
  },
  {
    path:'/consultition',
    component:Consultition
  },

]



const router=new VueRouter({
    routes,
    mode:'hash'
    // mode:'history',
    // scrollBehavior(to, from, savedPosition) {
    //   if (savedPosition) {
    //     // 如果有保存的位置，则滚动到该位置
    //     return savedPosition;
    //   } else if (to.hash) {
    //     // 如果指定了 hash，尝试滚动到对应的元素
    //     return { selector: to.hash };
    //   } else {
    //     // 默认滚动到页面顶部
    //     return { x: 0, y: 0 };
    //   }
    // },
  })

router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
})  
  
export default router